import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { StrictHttpRequestBuilder } from "./strict-http-request.builder";

export class StrictApiRequestService {
  constructor(
    private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {
    this.baseUrl = this.baseUrl.replace(/\/+$/, "").trimEnd();
  }

  public path(path: string): StrictHttpRequestBuilder {
    path = path.replace(/^\/+/, "").trimStart();
    const url = path ? `${this.baseUrl}/${path}` : this.baseUrl;

    return new StrictHttpRequestBuilder(url, this.http, this.authService);
  }
}
