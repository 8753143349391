import { Injectable } from "@angular/core";
import { AuthService } from "../../../../harmony/angular";
import { AuthTokenService } from "./auth-token.service";

@Injectable()
export class AuthNetworkService implements AuthService {
  constructor(private readonly auth: AuthTokenService) {}

  public getAuthHeaders(): Record<string, string> {
    return {
      Authorization: "Bearer " + this.auth.userToken,
    };
  }
}
