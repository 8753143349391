import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";

export enum SavingStatuses {
  Stopped = "stopped",
  Running = "running",
  Success = "success",
}

@Component({
  selector: "app-autosave-tooltip",
  templateUrl: "./autosave-tooltip.component.html",
  styleUrl: "./autosave-tooltip.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AutosaveTooltipComponent {
  protected SavingStatuses = SavingStatuses;
  protected status: SavingStatuses;

  private timeout?: ReturnType<typeof setTimeout>;

  constructor(private cdr: ChangeDetectorRef) {
    this.status = SavingStatuses.Stopped;
  }

  @Input() set running(running: boolean) {
    if (!running && this.status === SavingStatuses.Running) {
      this.status = SavingStatuses.Success;
      this.timeout = setTimeout(() => this.stop(), 2000);
    }

    if (running) {
      this.status = SavingStatuses.Running;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }
  }

  get running(): boolean {
    return this.status === SavingStatuses.Running;
  }

  @Input() set error(error: boolean) {
    if (error) {
      this.stop();
      this.status = SavingStatuses.Stopped;
    }
  }

  private stop(): void {
    this.status = SavingStatuses.Stopped;
    this.cdr.detectChanges();
  }
}
