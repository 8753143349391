import { Injectable, Signal, inject, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { AppData, AppDataParser } from "../../models/appData";
import { AuthNetworkService } from "./auth.network.service";
import { StrictApiRequestService } from "../../http/strict-api-request.service";
import { environment } from "../../../../environments/environment";
import { assert } from "../../utils/assert";

export const getAppDataSignal = (): Signal<AppData> =>
  inject(AppDataService).getSignal();

@Injectable()
export class AppDataService {
  private readonly apiRequestService: StrictApiRequestService;
  private appData?: Promise<AppData>;
  private appDataSignal = signal<AppData | undefined>(undefined);

  constructor(httpClient: HttpClient, authNetworkService: AuthNetworkService) {
    this.apiRequestService = new StrictApiRequestService(
      environment.api.baseUrl + environment.api.apiPathV1,
      httpClient,
      authNetworkService,
    );
  }

  public async get(): Promise<AppData> {
    if (!this.appData) {
      this.appData = this.apiRequestService
        .path(`data?domain=${encodeURI(window.location.hostname)}`)
        .setParser(AppDataParser)
        .get();

      this.appData.then((data) => {
        this.appDataSignal.set(data);
      });
    }

    return this.appData;
  }

  public getSignal(): Signal<AppData> {
    assert(this.appDataSignal());
    return this.appDataSignal.asReadonly() as Signal<AppData>;
  }
}
