import { Pipe, PipeTransform } from "@angular/core";

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({ name: "fileSize" })
export class FileSizePipe implements PipeTransform {
  private units = ["bytes", "KB", "MB", "GB", "TB", "PB"];

  public transform(bytes: number | string = 0, precision = 2): string {
    let parsedBytes = parseFloat(String(bytes));

    if (isNaN(parsedBytes) || !isFinite(parsedBytes)) {
      return "?";
    }

    let unit = 0;

    while (parsedBytes >= 1024) {
      parsedBytes /= 1024;
      unit++;
    }

    return Number(parsedBytes).toFixed(+precision) + " " + this.units[unit];
  }
}
