import { HttpParams } from "@angular/common/http";
import { ParameterType } from "./http-request.builder";

export class UrlBuilder {
  private queryParams?: HttpParams;

  constructor(private url: string) {}

  public getUrl(): string {
    return this.url;
  }

  public getQueryParams(): HttpParams | undefined {
    return this.queryParams;
  }

  public setUrlParams(urlParams: Record<string, ParameterType>): UrlBuilder {
    this.url = this.prepareUrlParams(this.url, urlParams);
    return this;
  }

  public setQueryParams(
    params: Record<string, ParameterType | undefined>,
  ): UrlBuilder {
    const queryParams = this.prepareQueryParams(params);
    if (Object.keys(queryParams).length) {
      this.queryParams = new HttpParams({ fromObject: queryParams });
    }
    return this;
  }

  private prepareUrlParams(
    url: string,
    urlParams: Record<string, ParameterType>,
  ): string {
    for (const property in urlParams) {
      if (
        Object.prototype.hasOwnProperty.call(urlParams, property) &&
        url.indexOf(`:${property}`) > -1
      ) {
        url = url.replace(`:${property}`, urlParams[property].toString());
      } else {
        throw new Error(
          `Parameter "${property}" does not exist in URL "${url}"`,
        );
      }
    }
    return url;
  }

  private prepareQueryParams(
    queryParams: Record<string, ParameterType | undefined>,
  ): Record<string, ParameterType> {
    return Object.entries(queryParams)
      .filter(
        ([, value]) => value !== undefined && value !== null && value !== "",
      )
      .reduce(
        (obj, [key, value]) => {
          obj[key] = value!;
          return obj;
        },
        {} as Record<string, ParameterType>,
      );
  }
}
