import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, lastValueFrom, of, throwError, delay, switchMap } from "rxjs";
import { z } from "zod";
import { Mapper } from "../../../harmony/core";
import { RequestOptions } from "./angular-http-request.builder";
import { AuthService } from "./auth.service";
import { ParameterType } from "./http-request.builder";
import { UrlBuilder } from "./url-builder";

// Thanks: https://stackoverflow.com/a/61626123
type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N;

/**
 * Returns first non `any` type from the generics list.
 *
 * if      T !== any use T
 * else if U !== any use U
 * else              use any
 */
type NonAny<T, U> = IfAny<T, IfAny<U, any, U>, T>;

type Method = "get" | "patch" | "post" | "put" | "delete";

interface FakeDataConfig<Entity> {
  /** Fake data to use instead of the real response. */
  data: Entity;
  /** Simulated network delay in milliseconds. Default: `1500`. */
  delay?: number;
  /** `0-1` value that determines how often we get an error. `0` = never, `1` = always. Default: `0.5` */
  errorRate?: number;
}

export class StrictHttpRequestBuilder<Entity = any, Model = any> {
  private readonly urlBuilder: UrlBuilder;

  private fakeDataConfig?: Required<FakeDataConfig<Entity>>;
  private method?: Method;
  private mapper?: Mapper<Entity, Model>;
  private parser?: z.ZodType<Entity>;
  private body: string | FormData | null = null;

  constructor(
    endpoint: string,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {
    this.urlBuilder = new UrlBuilder(endpoint);
  }

  private createRequestOptions(): RequestOptions {
    const httpParams = this.urlBuilder.getQueryParams();
    const isJsonBody = !(this.body instanceof FormData);
    const headers = new HttpHeaders({
      Accept: "application/json",
      ...(isJsonBody ? { "Content-Type": "application/json" } : {}),
      ...this.authService.getAuthHeaders(),
    });

    return {
      observe: "response",
      responseType: "json",
      headers: headers,
      ...(httpParams && { params: httpParams }),
    };
  }

  public setUrlParams(urlParams: Record<string, ParameterType>): this {
    this.urlBuilder.setUrlParams(urlParams);
    return this;
  }

  public setQueryParams(
    queryParams: Record<string, ParameterType | undefined>,
  ): this {
    this.urlBuilder.setQueryParams(queryParams);
    return this;
  }

  public setBody(body: unknown): this {
    if (body instanceof FormData) {
      this.body = body;
    } else {
      this.body = JSON.stringify(body);
    }

    return this;
  }

  /**
   * SAFETY: Strictly speaking, if no `parser` is provided this doesn't assert
   * that `data` is Entity. But we can roll with it.
   */
  private assertEntity(data: unknown): asserts data is Entity {
    const queryParams = this.urlBuilder.getQueryParams();
    const url = `${this.method?.toUpperCase()} ${this.urlBuilder.getUrl()}${
      queryParams ? `?${queryParams.toString()}` : ""
    }`;

    if (this.parser) {
      let result = this.parser.safeParse(data);

      if (!result.success) {
        // SAFETY: For some reason `result` narrowing doesn't work so we need this
        result = result as z.SafeParseError<Entity>;

        const issues = result.error.issues
          .map((issue) => `${issue.path.join(".")}: ${issue.message}`)
          .join("\n");

        // TODO: Report issue to Sentry
        console.error(`Response parsing error: ${url}\n${issues}\n`);
      }
    } else {
      console.info(`Response parser not set for: ${url}`);
    }
  }

  private mapResponse(data: unknown): NonAny<Model, Entity> {
    this.assertEntity(data);

    if (this.mapper) {
      return this.mapper.map(data) as NonAny<Model, any>;
    } else {
      return data as NonAny<Model, Entity>;
    }
  }

  public setParser<Entity>(
    parser: z.ZodType<Entity>,
  ): StrictHttpRequestBuilder<Entity, Model> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this as unknown as StrictHttpRequestBuilder<Entity, Model>;

    self.parser = parser;

    return self;
  }

  public setMapper<Model>(
    mapper: Mapper<Entity, Model>,
  ): StrictHttpRequestBuilder<Entity, Model> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this as unknown as StrictHttpRequestBuilder<Entity, Model>;

    self.mapper = mapper;

    return self;
  }

  /**
   * Sets the fake data to use instead of the real response
   */
  public setFakeData(config: FakeDataConfig<Entity>): this {
    this.fakeDataConfig = {
      delay: 1500,
      errorRate: 0.5,
      ...config,
    };

    return this;
  }

  private simulateErrorOrReturnFakeData(): Promise<NonAny<Model, Entity>> {
    const { data, delay: delayMs, errorRate } = this.fakeDataConfig!;

    return lastValueFrom(
      of(null).pipe(
        delay(delayMs),
        switchMap(() =>
          Math.random() < errorRate
            ? throwError(
                () => new Error(`Simulated HTTP error (rate=${errorRate})`),
              )
            : of(data as Entity),
        ),
        map((res) => this.mapResponse(res)),
      ),
    );
  }

  private request(method: Method): Promise<NonAny<Model, Entity>> {
    this.method = method;

    if (this.fakeDataConfig) {
      return this.simulateErrorOrReturnFakeData();
    }

    const isBodyMethod = ["patch", "post", "put"].includes(method);

    return lastValueFrom(
      this.http
        .request(method, this.urlBuilder.getUrl(), {
          ...this.createRequestOptions(),
          ...(isBodyMethod && { body: this.body }),
        })
        .pipe(map((res) => this.mapResponse(res.body))),
    );
  }

  public get(): Promise<NonAny<Model, Entity>> {
    return this.request("get");
  }

  public patch(): Promise<NonAny<Model, Entity>> {
    return this.request("patch");
  }

  public post(): Promise<NonAny<Model, Entity>> {
    return this.request("post");
  }

  public put(): Promise<NonAny<Model, Entity>> {
    return this.request("put");
  }

  public delete(): Promise<NonAny<Model, Entity>> {
    return this.request("delete");
  }
}
