@if (status !== SavingStatuses.Stopped) {
  <div class="loading-container" [@enterAnimation]>
    <p>{{ "shared.saving" | translate }}</p>
    <div class="feedback">
      @switch (status) {
        @case (SavingStatuses.Running) {
          <mat-spinner diameter="24" />
        }

        @case (SavingStatuses.Success) {
          <mat-icon>check</mat-icon>
        }
      }
    </div>
  </div>
}
